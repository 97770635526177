<template>
  <div>
    <div style="border: 1px solid #ccc; margin-top: 10px">
      <!-- 工具栏 -->
      <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" />
      <!-- 编辑器 -->
      <Editor style="height: 450px; overflow-y: hidden" :defaultConfig="editorConfig" v-model="html" @onChange="onChange" @onCreated="onCreated" />
    </div>
  </div>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { DomEditor } from '@wangeditor/editor'

import { getAliOssSts } from '@/components/file-upload/api'
import ossClient, { getFilePath, getSystemPath } from '@/components/file-upload/oss.js'
import { localget, localset } from '@/utils/localStorage'

export default {
  name: 'MyEditor',
  components: { Editor, Toolbar },
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // oss上传配置项
      uploadConf: {},
      editor: null,
      html: '',
      toolbarConfig: {
        /* 显示哪些菜单，如何排序、分组 */

        toolbarKeys: [
          'headerSelect',
          'bold',
          'underline',
          'italic',
          'through',
          'clearStyle',
          'color',
          'bgColor',
          'fontSize',
          'fontFamily',
          'lineHeight',
          'indent',
          'delIndent',
          'justifyLeft',
          'justifyRight',
          'justifyCenter',
          'justifyJustify',
          'insertImage',
          'deleteImage',
          'editImage',
          'viewImageLink',
          'imageWidth30',
          'imageWidth50',
          'imageWidth100',
          'divider',
          'emotion',
          'insertLink',
          'editLink',
          'unLink',
          'viewLink',
          'header1',
          'header2',
          'header3',
          'header4',
          'header5',
          'todo',
          'redo',
          'undo',
          'bulletedList',
          'numberedList',
          'uploadImage'
        ]
        // excludeKeys: [ ], /* 隐藏哪些菜单 */
      },
      editorConfig: {
        placeholder: '请输入',
        autoFocus: false,
        readOnly: false, // 只读、不可编辑
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {
          uploadImage: {
            // async customUpload(file, insertFn) {
            //
            // },
            customUpload: (file, insertFn) => {
              let filePath = getFilePath(this.$route.name, file.name)
              if (file.size > 6291456) {
                this.$message.error('资源文件大小超出范围')
                return
              }
              ossClient(this.uploadConf).put(filePath, file).then((res)  => {
                insertFn(res.url, '', res.url) // 将图片插入编辑器显示
              })
              // return ossClient(this.uploadConf).put(filePath, file)
            }
          }
        }
      }
    }
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.html = this.content
      })
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 【注意】一定要用 Object.seal() 否则会报错
      // console.log(this.editor.getAllMenuKeys())
    },
    onChange(editor) {
      // console.log('onChange', editor.getHtml()) // onChange 时获取编辑器最新内容
      // const toolbar = DomEditor.getToolbar(editor)
      // console.log("工具栏配置", toolbar.getConfig().toolbarKeys ); // 工具栏配置
      this.$emit('update:content', editor.getHtml())
    },
    getEditorText() {
      const editor = this.editor
      if (editor == null) return
      // console.log(editor.getText()); // 执行 editor API
    },
    printEditorHtml() {
      const editor = this.editor
      if (editor == null) return
      // console.log(editor.getHtml()); // 执行 editor API
    }
  },
  mounted() {
    this.uploadConf = localget('alioss')
    if (!this.uploadConf) {
      getAliOssSts().then(res => {
        this.uploadConf = {
          region: 'oss-cn-hangzhou',
          accessKeyId: res.accessKeyId,
          accessKeySecret: res.accessKeySecret,
          bucket: res.bucket,
          stsToken: res.securityToken
        }

        localset('alioss', this.uploadConf, 1800)
      })
    }
    // 模拟 ajax 请求，异步渲染编辑器
    // setTimeout(() => {
    //   this.html = this.content
    // }, 1500)
    this.init()
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁 editor ，重要！！！
  }
}
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
